<template>
  <Navbar />
  <router-view />
  <Footer />
</template>

<script>
import $ from "jquery";
import Navbar from "@/layout/nav-bar.vue";
import Footer from "@/layout/Footer.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  data() {},

  mounted() {
    //back to top button control
    const $dom = $("html, body");
    const $window = $(window);
    const scrollTopBtn = $("#scroll-top");
    if ($window.scrollTop() >= 300) {
      console.log($(this).scrollTop(), "scroll");
      scrollTopBtn.show();
    } else {
      scrollTopBtn.hide();
    }
    $window.on("scroll", function () {
      if ($(this).scrollTop() > 200) {
        $(".header-menu-content").addClass("fixed-top");

        $("body").css(
          "margin-top",
          $(".header-menu-content").outerHeight() + "px"
        );
      } else {
        $(".header-menu-content").removeClass("fixed-top");
        $("body").css("margin-top", "0");
      }

      if ($(this).scrollTop() >= 300) {
        console.log($(this).scrollTop(), "scroll");
        scrollTopBtn.show();
      } else {
        scrollTopBtn.hide();
      }
    });
    $(document).on("click", "#scroll-top", function () {
      $($dom).animate({ scrollTop: 0 }, 1000);
    });
    // mobile menu
    /*=========== Main menu open ============*/
    var mainMenuToggle = $(".main-menu-toggle");

    mainMenuToggle.on("click", function () {
      $(".main-off-canvas-menu, .body-overlay").addClass("active");
      $("body").css({ overflow: "hidden" });
    });

    /*=========== Main menu close ============*/
    var mainMenuClose = $(".main-menu-close, .body-overlay");

    mainMenuClose.on("click", function () {
      $(".main-off-canvas-menu, .body-overlay").removeClass("active");
      $("body").css({ overflow: "inherit" });
    });

    /*=========== User menu open ============*/
    var userMenuToggle = $(".user-menu-toggle");

    userMenuToggle.on("click", function () {
      $(".user-off-canvas-menu, .body-overlay").addClass("active");
      $("body").css({ overflow: "hidden" });
    });

    /*=========== User menu close ============*/
    var userMenuClose = $(".user-menu-close, .body-overlay");

    userMenuClose.on("click", function () {
      $(".user-off-canvas-menu, .body-overlay").removeClass("active");
      $("body").css({ overflow: "inherit" });
    });
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@import "assets/css/bootstrap.min.css";
@import "assets/css/bootstrap-rtl.min.css";
@import "assets/css/line-awesome.css";
@import "assets/css/owl.carousel.min.css";
@import "assets/css/owl.theme.default.min.css";
@import "assets/css/bootstrap-select.min.css";
@import "assets/css/fancybox.css";
@import "assets/css/tooltipster.bundle.css";
@import "assets/css/style.css";
@import "assets/css/style-rtl.css";
@import "assets/scss/ar.scss";
@font-face {
  font-family: "Al-Jazeera-Arabic";
  src: url("@/assets/fonts/Al-Jazeera-Arabic Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
</style>
<style lang="scss">
@import "assets/scss/main-style.scss";
@import "assets/scss/researches.scss";
@import "assets/scss/contact.scss";
@import "assets/scss/account.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
// body {
//   margin: 0px !important;
// }

// @import "assets/vendor/bootstrap/css/bootstrap-ar.min";
// @import "assets/scss/partials/_variables.css";
// @import "assets/scss/partials/_custom.scss";
</style>
