<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="div-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-right">
          <div class="div">
            <img
              loading="lazy"
              srcset="../assets/img/sotor-imgs/footer-logo.png"
              class="img"
            />
            <div class="div-2">
              دار سطور المعرفة متخصصة في تقديم خدمات النشر في التخصصات العلمية
              بمختلف المجالات هادفة إلى تحسين جودة النشر العلمي في السعودية
            </div>
            <div class="div-3">منصة سطور لنشر الأبحاث العلمية</div>
            <div class="div-4">
              <div class="div-8">
                <router-link to="/">الرئيسية</router-link>
              </div>
              <div class="div-7">
                <router-link to="/researches">الأبحاث المنشورة</router-link>
              </div>
              <div class="div-6">
                <router-link to="/about-us"> عن سطور</router-link>
              </div>
              <div class="div-5">
                <router-link to="/contact-us">تواصل معنا</router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 text-center contact">
          <div class="div">
            <div class="div-2">تواصل معنا</div>
            <div class="div-3-contact">linesofknowledge6@gmail.com</div>
            <div class="div-4">+00966566432362</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="div-copy">© حقوق الطبع والنشر محفوظة لدى منصة سطور</div>
      </div>
    </div>
  </div>
</template>

<style>
.div-footer {
  padding-top: 50px;
  justify-content: flex-end;
  background-color: #000984;
  display: flex;
  flex-direction: column;
  color: #fff;
}
@media (max-width: 991px) {
  .div-footer {
    padding-right: 10px;
  }
}
style > .div-footer {
  display: flex;
  max-width: 340px;
  flex-direction: column;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.5px;
}
</style>
