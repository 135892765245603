<template>
  <header class="header-menu-area">
    <!-- end header-top -->
    <div class="header-menu-content pr-150px pl-150px">
      <div class="container">
        <div class="main-menu-content">
          <a href="#" class="down-button"><i class="la la-angle-down"></i></a>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <div class="logo-box">
                <a href="#" class="logo"
                  ><img
                    src="../assets/img/sotor-imgs/logo3.png"
                    alt="logo"
                    height="90"
                    width="90"
                /></a>
                <div class="user-btn-action">
                  <div
                    class="off-canvas-menu-toggle main-menu-toggle icon-element icon-element-sm shadow-sm"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Main menu"
                  >
                    <i class="la la-bars"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col-lg-2 -->
            <div class="col-lg-8">
              <div class="menu-wrapper text-center">
                <nav class="main-menu font-weight-bold">
                  <ul>
                    <li>
                      <router-link
                        to="/"
                        aria-controls="aboutus"
                        aria-selected="false"
                      >
                        الرئيسية</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/researches"
                        aria-controls="services"
                        aria-selected="false"
                      >
                        الأبحاث المنشورة</router-link
                      >
                    </li>
                    <li v-if="isLogged">
                      <router-link
                        to="/your-researches"
                        aria-controls="services"
                        aria-selected="false"
                      >
                        أبحاثي
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/about-us"
                        aria-controls="policy"
                        aria-selected="false"
                      >
                        عن سطور</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us"> تواصل معنا</router-link>
                    </li>
                    <!-- <li>
                      <router-link to="/profile"> الملف الشخصي</router-link>
                    </li> -->
                  </ul>
                  <!-- end ul -->
                </nav>
                <!-- end nav-right-button -->
              </div>
              <!-- end menu-wrapper -->
              <div
                class="off-canvas-menu custom-scrollbar-styled main-off-canvas-menu"
              >
                <div
                  class="off-canvas-menu-close main-menu-close icon-element icon-element-sm shadow-sm"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Close menu"
                >
                  <i class="la la-times"></i>
                </div>
                <!-- end off-canvas-menu-close -->
                <div class="mt-100px mr-10px">
                  <ul>
                    <li>
                      <router-link
                        to="/"
                        aria-controls="aboutus"
                        aria-selected="false"
                      >
                        الرئيسية</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/researches"
                        aria-controls="services"
                        aria-selected="false"
                      >
                        الأبحاث المنشورة</router-link
                      >
                    </li>
                    <li v-if="isLogged">
                      <router-link
                        to="/your-researches"
                        aria-controls="services"
                        aria-selected="false"
                      >
                        أبحاثي
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/about-us"
                        aria-controls="policy"
                        aria-selected="false"
                      >
                        عن سطور</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us"> تواصل معنا</router-link>
                    </li>
                    <li v-if="!isLogged">
                      <router-link to="/login">
                        <div class="div-nav-btn">
                          <div class="div-2-nav-btn">تسجيل دخول</div>
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ef5ffca69c46c2b4d958f3c6bf6ddb00645ca57023de58740bad94617e79ce5?"
                            class="img-nav-btn"
                          />
                        </div>
                      </router-link>
                    </li>
                    <li v-else>
                      <div @click="logout" class="col-lg-2">
                        <button class="div-nav-btn">
                          <div class="div-2-nav-btn">تسجيل خروج</div>
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ef5ffca69c46c2b4d958f3c6bf6ddb00645ca57023de58740bad94617e79ce5?"
                            class="img-nav-btn"
                          />
                        </button>
                      </div>
                    </li>
                  </ul>
                  <!-- end ul -->
                </div>
              </div>
              <!-- end off-canvas-menu -->
              <div class="body-overlay"></div>
            </div>
            <div v-if="!isLogged" class="col-lg-2 d-none d-md-block">
              <router-link to="/login">
                <div class="div-nav-btn">
                  <div class="div-2-nav-btn">تسجيل دخول</div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ef5ffca69c46c2b4d958f3c6bf6ddb00645ca57023de58740bad94617e79ce5?"
                    class="img-nav-btn"
                  />
                </div>
              </router-link>
            </div>
            <div v-else @click="logout" class="col-lg-2 d-none d-md-block">
              <button class="div-nav-btn">
                <div class="div-2-nav-btn">تسجيل خروج</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ef5ffca69c46c2b4d958f3c6bf6ddb00645ca57023de58740bad94617e79ce5?"
                  class="img-nav-btn"
                />
              </button>
            </div>

            <!-- end col-lg-10 -->
          </div>
          <!-- end row align-items-center -->
        </div>
        <!-- end main-menu-content -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end header-menu-content -->
  </header>
  <!-- start scroll top -->
  <div id="scroll-top">
    <i class="la la-arrow-up" title="Go top"></i>
  </div>
  <!-- end scroll top -->
</template>
<script>
import { ref, getCurrentInstance, computed, watch } from "vue";
import Swal from "sweetalert2";
export default {
  setup() {
    const instance = getCurrentInstance();
    const sotorToken = ref(localStorage.getItem("sotorToken"));

    const isLogged = computed(() => {
      return sotorToken.value !== "undefined" && sotorToken.value !== null;
    });

    watch(
      () => sotorToken.value,
      (newToken) => {
        console.log(newToken, "watch");
        if (newToken === null || newToken === "undefined") {
          isLogged.value = false;
        } else {
          isLogged.value = true;
        }
      }
    );
    const logout = () => {
      const current = instance.proxy;
      const formData = new FormData();
      formData.append("token", localStorage.sotorToken);

      current.$http
        .post(`${current.$hostUrl}logout`, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.sotorToken,
          },
        })
        .then((response) => {
          if (!response.data.error) {
            localStorage.removeItem("sotorToken");
            sotorToken.value = null;
            localStorage.clear();
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            current.$router.push("/login");
          } else {
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: response.data.data.message,
              showConfirmButton: true,
            });
            localStorage.sotorToken = response.data.data.token;
          }
        })
        .catch(function (error) {
          if (Object.prototype.hasOwnProperty.call(error, "response")) {
            const data = (error.response || {}).data;
            if (data.error) {
              Swal.fire({
                icon: "error",
                title: "... خطأ",
                text: data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire("حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا");
            }
          } else {
            Swal.fire("حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا");
          }
        });
    };

    return {
      isLogged,
      logout,
    };
  },
  methods: {
    scrollToSection(sectionId) {
      // Prevent the default link behavior
      var section = document.getElementById(sectionId);
      if (section) {
        // Scroll smoothly to the section
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
<style>
.logout-img {
  width: 26px;
  height: auto;
  filter: hue-rotate(0deg);
}

.logout-img.red {
  filter: hue-rotate(
    120deg
  ); /* Adjust the value to get the desired shade of red */
}
.logout-con a {
  cursor: pointer;
}
</style>
